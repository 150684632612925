import React, { useEffect, useState, useRef } from "react";
import Banner from "../components/Banner/Banner.jsx";
import Footer from "../components/Footer/Footer.jsx";
import "./PagesStyles.css";
import MenuPerfil from "../components/MenuPerfil/MenuPerfil.jsx";
import Adicionar from "../components/Calendario/Adicionar.jsx";
import AdicionarEvento from "../components/Calendario/AdicionarEvento.jsx";
import "moment/locale/pt-br";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "../components/Calendario/Calendario.css";
import EventModal from "../components/Calendario/EventModal";
import { readDisponibilidade, createDisponibilidade, deleteDisponibilidade, updateDisponibilidade, readUser } from "../services/api";
import EventoPadrao from "../components/Calendario/EventoPadrao.jsx";
import { CircularProgress, Typography, Box, Backdrop, TextField, MenuItem } from "@mui/material";
import { addMonths, eachDayOfInterval, endOfMonth, isEqual } from 'date-fns';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import { isSameDay } from "date-fns/isSameDay";
import ptBR from 'date-fns/locale/pt-BR';
import { mesDisponibilidade } from "../components/VariaveisOpcoes/VariaveisOpcoes.jsx";
import { StyledBtn } from "../components/StyledButton/StyledButton.jsx";
import CustomToolbar from "../components/Calendario/CustomToolbar.jsx";
import { feriados } from "../components/Calendario/Feriados.jsx";
import { PanToolTwoTone } from "@mui/icons-material";

const DragAndDropCalendar = withDragAndDrop(Calendar);
const locales = {
    'pt-BR': ptBR,
};

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek: () => startOfWeek(new Date(), { locale: ptBR }),
    getDay,
    locales,
});

const diaDaSemanaMap = {
    'domingo': 0,
    'segunda-feira': 1,
    'terça-feira': 2,
    'quarta-feira': 3,
    'quinta-feira': 4,
    'sexta-feira': 5,
    'sábado': 6
};

export default function Disponibilidade() {
    const token = localStorage.getItem("token");
    const [loaded, setLoaded] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    // const [disponibilidade, setDisponibilidade] = useState("");
    const [disponibilidade, setDisponibilidade] = useState(EventoPadrao);
    const [existeDisponibilidade, setExisteDisponibilidade] = useState(true);
    const moment = require("moment");
    const [eventos, setEventos] = useState(disponibilidade);
    // const [eventos, setEventos] = useState(EventoPadrao);
    const [eventoSelecionado, setEventoSelecionado] = useState(null);
    const [ativaAdicionar, setAtivaAdicionar] = useState(false);
    const [ativaAdicionarEvento, setAtivaAdicionarEvento] = useState(false);
    const [selecionaCalendario, setSelecionaCalendario] = useState(false);
    const [calendarioPreenchido, setCalendarioPreenchido] = useState(false);
    const [talentoFullName, setTalentoFullName] = useState("");
    const [talentoCompany, setTalentoCompany] = useState("");
    const [mesDisp, setMesDisp] = useState("");
    const [abreMes, setAbreMes] = useState(false);
    const [diaSelecionado, setDiaSelecionado] = useState(null);
    const recaptchaRef = useRef();
    const [date, setDate] = useState(new Date());

    const dayPropGetter = (date) => {
        // Verifica se a data é um feriado
        const isHoliday = feriados.some((feriado) => isSameDay(date, feriado));
        if (isHoliday) {
            return {
                style: {
                    backgroundColor: "#00ff6970", // Cor de fundo dos feriados (verde claro)
                },
            };
        }
        return {};
    };

    useEffect(() => {
        async function fetchBanner() {
            const bannerResponse = await readUser(token);
            console.log("bannerResponse", bannerResponse);
            const fullName = { full_name: bannerResponse.data.full_name };
            console.log("FullName: ", fullName);
            setTalentoFullName(fullName);
            console.log("TalentoFullName", talentoFullName);
            const companyName = { company: bannerResponse.data.agency[0].companyId.name };
            console.log("Company: ", companyName);
            setTalentoCompany(companyName);
            setLoaded(true);
        }
        fetchBanner();
        // }, [token, talentoFullName]);
    }, []);

    useEffect(() => {
        async function fetchReadDisponibilidade() {
            lerDisponibilidade();
        }
        fetchReadDisponibilidade();
        // }, [token, moment]);
    }, []);

    const eventStyle = (event) => ({
        style: {
            backgroundColor: event.color,
        },
        title: `${moment(event.start).format('HH:mm')} - ${moment(event.end).format('HH:mm')}`,
    });

    async function lerDisponibilidade() {
        // setLoaded(false); // Inicia o carregamento
        const readDisponibilidadeResponse = await readDisponibilidade(token);
        // setLoaded(true);
        if (readDisponibilidadeResponse.data.rows.length !== 0) {
            const select = readDisponibilidadeResponse.data.rows.map((selecao) => {
                const dateStart = String(new Date(selecao.start)).slice(16, 21);
                const dateEnd = String(new Date(selecao.end)).slice(16, 21);
                if ((dateStart === "08:00" && dateEnd === "12:00") || (dateStart === "09:00" && dateEnd === "13:00")) {
                    return {
                        id: selecao.id,
                        title: selecao.title,
                        start: new Date(selecao.start),
                        end: new Date(selecao.end),
                        color: "#f7a600"
                    };
                } else if ((dateStart === "12:00" && dateEnd === "16:00") || (dateStart === "13:00" && dateEnd === "17:00")) {
                    return {
                        id: selecao.id,
                        title: selecao.title,
                        start: new Date(selecao.start),
                        end: new Date(selecao.end),
                        color: "#701c7f"
                    };
                } else if ((dateStart === "00:00" && dateEnd === "00:00")) {
                    return {
                        id: selecao.id,
                        title: selecao.title,
                        start: new Date(selecao.start),
                        end: new Date(selecao.end),
                        color: "#1bb91b"
                    };
                } else {
                    return {
                        id: selecao.id,
                        title: selecao.title,
                        start: new Date(selecao.start),
                        end: new Date(selecao.end),
                        color: "#888888"
                    };
                };
            });
            let resultado = [...select].sort((a, b) => a.start > b.start ? 1 : -1);
            setDisponibilidade(resultado);
            setEventos(resultado);
            console.log("RESULTADO_LerDisponibilidade: ", resultado);
        } else {
            const newEventos = await saveEventoDB(EventoPadrao);
            // setSelecionaCalendario(true);
        };
        setLoaded(true); // Finaliza o carregamento
    }

    const moverEventos = (data) => {
        const { start, end } = data;
        const updatedEvents = eventos.map((event) => {
            if (event.id === data.event.id) {
                return {
                    ...event,
                    start: new Date(start),
                    end: new Date(end),
                };
            }
            return event;
        });
        setEventos(updatedEvents);
    };

    const handleEventUpdate = (updatedEvent) => {
        const updatedEvents = eventos.map((event) => {
            if (event.id === updatedEvent.id) {
                return updatedEvent;
            };
            return event;
        });
        setEventos(updatedEvents);
        setDisponibilidade(updatedEvents);
        const resposta = updateDisponibilidade(token, updatedEvent.id, updatedEvent);
        setEventoSelecionado(null);
    };

    const handleEventDelete = (eventId) => {
        const updatedEvents = eventos.filter((event) => event.id !== eventId);
        setEventos(updatedEvents);
        setDisponibilidade(updatedEvents);
        deleteDisponibilidade(token, eventId);
        setEventoSelecionado(null);
    };

    async function saveEventoDB(data) {
        const dadosDB = [];
        data.forEach(item => {
            dadosDB.push({
                title: item.title,
                start: item.start,
                end: item.end,
                color: item.color,
            })
        });
        const r = await createDisponibilidade(token, dadosDB);
        if (r.data) {
            return r.data;
        };
        return [];
    }

    const handleSelect = (novoEvento) => {
        setMesDisp(novoEvento.target.value);
        const month = parseInt(novoEvento.target.value, 10);
        console.log("MONTH: ", month);
        const newDate = new Date(date.getFullYear(), month, 1);
        setDate(newDate);
    };

    async function handleSave(novoEvento) {
        setLoaded(false);
        const currentDate = new Date();
        console.log("DIA: ", currentDate.getDay());
        console.log("Month: ", currentDate.getMonth(), " Disp: ", mesDisp);
        // const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
        // const startDate = new Date(currentDate.getFullYear(), mesDisp - 1, 1);
        const startDate = (mesDisp === currentDate.getMonth()) ? new Date(currentDate.getFullYear(), mesDisp, currentDate.getDate() + 1) : new Date(currentDate.getFullYear(), mesDisp, 1);
        const endDate = endOfMonth(startDate);
        const allDays = eachDayOfInterval({ start: startDate, end: endDate });
        const diaDaSemana = novoEvento.diaDaSemana.toLowerCase().trim();
        const dayIndex = diaDaSemanaMap[diaDaSemana];

        const gravaEvento = [];

        const novosEventos = allDays
            .filter(day => getDay(day) === dayIndex)
            .map(day => {
                const start = new Date(day);
                start.setHours(novoEvento.start.split(':')[0], novoEvento.start.split(':')[1]);
                const end = new Date(day);
                end.setHours(novoEvento.end.split(':')[0], novoEvento.end.split(':')[1]);
                const diaDaSemanaEmPortugues = diaDaSemanaMap[getDay(day)]; // Obtendo o nome do dia da semana em português
                return {
                    ...novoEvento,
                    diaDaSemana: diaDaSemanaEmPortugues,
                    id: `${start.toISOString()}`, // Usando a data de início para o ID
                    start,
                    end,
                };
            });

        for (let i in novosEventos) {
            gravaEvento.push(novosEventos[i]);
        }
        const newEventos = await saveEventoDB(gravaEvento);
        lerDisponibilidade();
        setAtivaAdicionar(false);
    };

    const handleEventClick = (evento) => {
        setEventoSelecionado(evento);
    };

    const handleEventClose = () => {
        setEventoSelecionado(null);
    };

    const handleCalendarioManual = () => {
        setSelecionaCalendario(true);
        setEventos(disponibilidade);
    }

    const ativaCalendarioPreenchido = () => {
        setAbreMes(true);
    }

    async function handleCalendarioPreenchido() {
        setLoaded(false);
        const novoEvento = [
            {
                id: "",
                title: "",
                start: "08:00",
                end: "12:00",
                color: "#f7a600",
            },
            {
                id: "",
                title: "",
                start: "13:00",
                end: "17:00",
                color: "#701c7f",
            }
        ];
        const currentDate = new Date();
        // const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
        const startDate = new Date(currentDate.getFullYear(), mesDisp, 1);
        const endDate = endOfMonth(startDate);
        const allDays = eachDayOfInterval({ start: startDate, end: endDate });
        const gravaEvento = [];
        for (let dia = 1; dia < 6; dia++) {
            novoEvento.map((index) => {
                const novosEventos = allDays
                    .filter(day => getDay(day) === dia)
                    .map(day => {
                        const start = new Date(day);
                        const end = new Date(day);
                        start.setHours(index.start.split(':')[0], index.start.split(':')[1]);
                        end.setHours(index.end.split(':')[0], index.end.split(':')[1]);
                        const color = index.color;
                        const title = index.title;
                        return {
                            ...eventos,
                            id: `${start.toISOString()}`, // Usando a data de início para o ID
                            title: title,
                            start: start,
                            end: end,
                            color: color,
                        };
                    });
                setSelecionaCalendario(true);
                for (let i in novosEventos) {
                    gravaEvento.push(novosEventos[i]);
                }
            });
        };
        const newEventos = await saveEventoDB(gravaEvento);
        lerDisponibilidade();
    };

    const handleSelectSlot = ({ start, end }) => {
        const dia = parseInt((String(start).slice(8, 10)));
        const dataDia = new Date(start.getFullYear(), start.getMonth(), dia);
        setDiaSelecionado(dataDia);
        const newEvento = {
            title: "",
            start,
            end,
            color: "",
        };
        setEventos([...eventos, newEvento]);
        console.log("Eventos: ", eventos);
        setAtivaAdicionarEvento(true);
    }

    let cont = 0;

    async function handleAdicionar(novoEvento) {
        setLoaded(false);
        const dataDia = new Date(diaSelecionado.getFullYear(), diaSelecionado.getMonth(), diaSelecionado.getDate());
        const gravaEvento = [];
        if (cont === 0) {
            cont++;
            const start = dataDia;
            const end = dataDia;
            const startDate = start.setHours(novoEvento.start.split(':')[0], novoEvento.start.split(':')[1]);
            const endDate = end.setHours(novoEvento.end.split(':')[0], novoEvento.end.split(':')[1]);
            const eventoNovo = {
                start: startDate,
                end: endDate,
            }
            gravaEvento.push(eventoNovo);
            const newEventos = await saveEventoDB(gravaEvento);
            lerDisponibilidade();
        }
        setAtivaAdicionarEvento(false);
    };

    const handleAtivaAdicionar = (novoEvento) => {
        setAtivaAdicionar(novoEvento);
    };

    const handleCloseAdicionar = (novoEvento) => {
        setAtivaAdicionar(novoEvento);
        setAtivaAdicionarEvento(novoEvento);
    };

    const CustomEvent = ({ event }) => {
        const handleDoubleClick = (e) => {
            e.stopPropagation(); // Garante que o clique não seja interceptado pelo wrapper
            handleEventClick(event);
        };
        return (
            <span className="custom-event" onDoubleClick={handleDoubleClick} >
                {moment(event.start).format('HH:mm')} - {moment(event.end).format('HH:mm')}
            </span>
        );
    };

    const formatarEvento = (evento) => {
        const timeStart = String(new Date(evento.start)).slice(16, 21);
        const timeEnd = String(new Date(evento.end)).slice(16, 21);
        if (timeStart === "00:00" && timeEnd === "00:00") {
            return (
                <div>
                    <div className="custom-event">
                        {evento.title}
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <div className="custom-event">
                        {moment(evento.start).format('HH:mm')} - {moment(evento.end).format('HH:mm')}
                    </div>
                </div>
            );
        }
    };

    const nextMonth = addMonths(new Date(), 1);
    const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

    //Incluído
    // if (!loaded) {
    //     return <div>Carregando Disponibilidade...</div>;
    // } else {

    return (
        <>
            {/* <div className="container-header">
                    <header className="app-header">
                        <Banner title="Disponibilidade" description="Disponibilidade Labora Tech." />
                    </header>
                </div>
                <div className="container-perfil">
                    <div className="container-menu">
                        <MenuPerfil></MenuPerfil>
                    </div> */}
            <div className="tela">
                <div>
                    <Backdrop
                        sx={{ color: "secondary.main", zIndex: 1, backgroundColor: "#ffffffcf" }}
                        open={!loaded}
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            height="100vh"
                        >
                            <CircularProgress sx={{ color: "secondary.main" }} />
                            <Typography variant="h7" component="div" sx={{ mt: 2, fontWeight: 700 }}>
                                Em processamento, aguarde
                                <PanToolTwoTone />
                            </Typography>
                        </Box>
                    </Backdrop>
                    {ativaAdicionar && (
                        <Adicionar onAdicionar={handleSave} onClose={handleCloseAdicionar} />
                    )}
                    {ativaAdicionarEvento && (
                        // <Adicionar onAdicionar={handleSave} onClose={handleCloseAdicionar} />
                        <AdicionarEvento onAdicionar={handleAdicionar} onClose={handleCloseAdicionar} />
                    )}
                    {/* <div className="toolbar">
                    </div> */}

                    {!selecionaCalendario && (
                        <div className="selecao">
                            <div>
                                <h1>Olá, {talentoFullName.full_name} !</h1>
                                <h2>Seja bem-vindo ao calendário das disponibilidades de horário para jornadas Labora.
                                    Você poderá gerar um calendário manualmente, por dias da semana, ou gerar um
                                    calendário automaticamente onde você fará as exclusões de datas/horários.</h2>
                                <h2>Os projetos ativos no momento são os listados abaixo:</h2>
                                <div className="projetos">
                                    <tr>
                                        <th className="th-agendar">PROJETO</th>
                                        <th>HORÁRIOS</th>
                                    </tr>
                                    <tr>
                                        <td className="th-agendar">All Set</td>
                                        <td>09:00-13:00 / 15:00-19:00</td>
                                    </tr>
                                    <tr>
                                        <td className="th-agendar">Camino / Cloe</td>
                                        <td>08:00-12:00 / 13:00-17:00</td>
                                    </tr>
                                    <tr>
                                        <td className="th-agendar">Casa Museu Ema Klabin</td>
                                        <td>11:00-15:00 / 12:00-16:00 / 13:00-17:00</td>
                                    </tr>
                                    <tr>
                                        <td className="th-agendar">Sebrae, Uplab, Plongê, Labora</td>
                                        <td>08:00-12:00 / 13:00-17:00</td>
                                    </tr>
                                    <tr>
                                        <td className="th-agendar">Treinamento</td>
                                        <td>09:00-13:00 / 14:00-18:00</td>
                                    </tr>
                                </div>

                                <h3>IMPORTANTE: É obrigatório seguir os horários, conforme Projeto.</h3>
                                <h2>Deseja que seu calendário seja gerado automaticamente?</h2>

                            </div>
                            <div className="alinhamento">
                                <>
                                    {!abreMes && (
                                        <div className="botao-agendar">
                                            <StyledBtn
                                                className="botao-agenda"
                                                variant="contained"
                                                color="secondary"
                                                onClick={handleCalendarioManual}
                                            >
                                                Não
                                            </StyledBtn>
                                        </div>
                                    )}
                                </>
                                <>
                                    {!abreMes && (
                                        <div className="botao-agendar">
                                            <StyledBtn
                                                className="botao-agenda"
                                                variant="contained"
                                                color="secondary"
                                                // onClick={() => handleCalendarioPreenchido()}
                                                onClick={ativaCalendarioPreenchido}
                                                disabled={abreMes}
                                            >
                                                Sim
                                            </StyledBtn>
                                        </div>
                                    )}
                                </>
                                {abreMes && (
                                    <>
                                        <div className="botao-agendar">
                                            <TextField
                                                className="textfield-2b"
                                                select
                                                // label="Mês"
                                                type="number"
                                                id="mesDisp"
                                                name="mesDisp"
                                                variant="outlined"
                                                size="small"
                                                helperText="Escolha o mês"
                                                sx={{
                                                    '& .MuiFormHelperText-root': {
                                                        color: '#e84927', // Cor personalizada para o helper text
                                                        fontWeight: "700",
                                                        // fontSize: '1rem',
                                                    },
                                                    // Estilo para o campo de entrada
                                                    '& .MuiOutlinedInput-root': {
                                                        color: '#ffffff', // Cor do texto do input
                                                        backgroundColor: '#e84927', // Cor de fundo do input
                                                        borderRadius: "15px",
                                                        '& fieldset': {
                                                            borderColor: '#e84927', // Cor da borda do campo
                                                        },
                                                        '&:hover fieldset': {
                                                            borderColor: '#f7a600', // Cor da borda ao passar o mouse
                                                            backgroundColor: '#f7a600',
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: '#e84927', // Cor da borda quando focado
                                                        },
                                                    },
                                                    // Estilo para o menu do select
                                                    '& .MuiSelect-icon': {
                                                        color: '#ffffff', // Cor do ícone do select
                                                    },
                                                    '&:hover .MuiSelect-icon': {
                                                        color: '#ffffff', // Cor do ícone do select
                                                    },
                                                }}
                                                onChange={handleSelect}
                                                defaultValue={""}
                                                value={date.getMonth()}
                                            >
                                                {mesDisponibilidade.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                        {/* {format(new Date(date.getFullYear(), option.value, 1), "MMMM")} */}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                        <div className="botao-agendar">
                                            <StyledBtn
                                                className="botao-agenda"
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => handleCalendarioPreenchido()}
                                                disabled={!mesDisp}
                                            >
                                                Gerar
                                            </StyledBtn>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}

                    {selecionaCalendario && (
                        <div className="calendario">
                            <div className="alinha-botoes">
                                <div className="botao-agendar">
                                    <TextField
                                        className="textfield-2c"
                                        select
                                        // label="Mês"
                                        type="number"
                                        id="mesDisp"
                                        name="mesDisp"
                                        variant="outlined"
                                        size="small"
                                        color="secondary"
                                        sx={{
                                            '& .MuiFormHelperText-root': {
                                                color: '#e84927', // Cor personalizada para o helper text
                                                fontWeight: "700",
                                                // fontSize: '1rem',
                                            },
                                            // Estilo para o campo de entrada
                                            '& .MuiOutlinedInput-root': {
                                                color: '#ffffff', // Cor do texto do input
                                                backgroundColor: '#e84927', // Cor de fundo do input
                                                borderRadius: "15px",
                                                '& fieldset': {
                                                    borderColor: '#e84927', // Cor da borda do campo
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: '#f7a600', // Cor da borda ao passar o mouse
                                                    backgroundColor: '#f7a600',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#e84927', // Cor da borda quando focado
                                                },
                                            },
                                            // Estilo para o menu do select
                                            '& .MuiSelect-icon': {
                                                color: '#ffffff', // Cor do ícone do select
                                            },
                                            '&:hover .MuiSelect-icon': {
                                                color: '#ffffff', // Cor do ícone do select
                                            },
                                        }}
                                        helperText="Escolha o mês"
                                        placeholder="Escolha o mês"
                                        onChange={handleSelect}
                                        // defaultValue={""}
                                        value={date.getMonth()}
                                    >
                                        {mesDisponibilidade.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                                {/* {format(new Date(date.getFullYear(), option.value, 1), "MMMM")} */}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <StyledBtn
                                        className="botao-agendas"
                                        variant="contained"
                                        color="secondary"
                                        onClick={handleAtivaAdicionar}
                                        disabled={!mesDisp}
                                    >
                                        Inserir disponibilidade
                                    </StyledBtn>
                                </div>
                            </div>
                            <DragAndDropCalendar
                                defaultDate={moment().toDate()}
                                // defaultDate={nextMonth}
                                defaultView="month"
                                events={eventos}
                                localizer={localizer}
                                resizable
                                onEventDrop={moverEventos}
                                onEventResize={moverEventos}
                                onSelectEvent={handleEventClick}
                                eventPropGetter={eventStyle}
                                className="calendar"
                                selectable
                                onSelectSlot={handleSelectSlot}
                                longPressThreshold={5}
                                startAccessor="start"
                                endAccessor="end"
                                titleAccessor={formatarEvento}
                                culture="pt-BR"
                                dayPropGetter={dayPropGetter}
                                // components={{ toolbar: CustomToolbar }}
                                components={{ toolbar: (props) => <CustomToolbar {...props} /> }}
                                formats={{
                                    monthHeaderFormat: (date, culture, localizer) =>
                                        capitalize(localizer.format(date, "MMMM yyyy", culture)),
                                }}
                                date={date}
                                onNavigate={setDate}
                            // messages={{
                            //     week: 'Semana',
                            //     work_week: 'Semana de trabalho',
                            //     day: 'Dia',
                            //     month: 'Mês',
                            //     previous: '<<',
                            //     next: '>>',
                            //     today: 'Hoje',
                            //     agenda: 'Agenda',
                            //     noEventsInRange: 'Não há eventos neste intervalo.',
                            //     date: 'Data',
                            //     time: 'Hora',
                            //     event: 'Evento',
                            // }}
                            />

                            {eventoSelecionado && (
                                <EventModal
                                    evento={eventoSelecionado}
                                    onClose={handleEventClose}
                                    onDelete={handleEventDelete}
                                    onUpdate={handleEventUpdate}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
            {/* </div >
                <Footer></Footer> */}
        </>
    );
};
// };
